// eslint-disable-next-line max-params
export function setCookie(
  name: string,
  value: string,
  duration: number,
  domain?: string
) {
  const expires = new Date()

  expires.setDate(expires.getDate() + duration)

  let cookie = `${name}=${encodeURIComponent(
    value
  )}; expires=${expires.toUTCString()}; path=/;`

  if (domain) {
    cookie += ` domain=${domain};`
  }

  document.cookie = cookie
}

export function getCookie(name: string) {
  const cookies = document.cookie
  const prefix = `${name}=`
  let begin = cookies.indexOf(`; ${prefix}`)

  if (begin === -1) {
    begin = cookies.indexOf(prefix)

    if (begin !== 0) {
      return null
    }
  } else {
    begin += 2
  }

  let end = cookies.indexOf(';', begin)

  if (end === -1) {
    end = cookies.length
  }

  return decodeURIComponent(cookies.substring(begin + prefix.length, end))
}

export function deleteCookie(name: string) {
  if (getCookie(name)) {
    document.cookie = `${name}=` + `; expires=Thu, 01-Jan-70 00:00:01 GMT`
  }
}

export async function getCookieFromHeaders(name: string, cookies: string[]) {
  for (const cookie of cookies) {
    const [cookieName, cookieValue] = cookie.trim().split('=')

    if (cookieName === name) {
      return cookieValue
    }
  }

  return ''
}

export function removeTrackingParams(url: string) {
  if (!url.includes('?')) return url;
  
  const [basePath, queryString] = url.split('?');
  
  if (!queryString) return basePath;
  
  const searchParams = new URLSearchParams(queryString);
  
  searchParams.delete('utm_source');
  searchParams.delete('gad_source');
  searchParams.delete('gclid');
  
  const newQueryString = searchParams.toString();
  
  return newQueryString ? `${basePath}?${newQueryString}` : basePath;
}
