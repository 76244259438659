import { useCallback } from 'react'

type Props = {
  value: number
  currency: string
  item_id: string
  item_name: string
  price: number
  quantity: number
  discount: number
  item_brand: string | null
  item_collection: string | null
  item_type: string | null
  item_reference: string | null
  item_material: string | null
  item_size: string | null
  item_stone: string | null
  item_weight: string | null
  item_audience: string
  user_logged: boolean
  user_id: string | null
}

export const useRemoveFromCart = () => {
  window.dataLayer = window.dataLayer || []
  const { dataLayer } = window

  const sendRemoveFromCartEvent = useCallback((props: Props) => {
    dataLayer.push({
      event: 'remove_from_cart',
      currency: props.currency,
      value: props.value,
      items: [
        {
          item_id: props.item_id,
          item_name: props.item_name,
          price: props.price,
          quantity: props.quantity,
          discount: props.discount,
          item_brand: props.item_brand,
          item_collection: props.item_collection,
          item_type: props.item_type,
          item_reference: props.item_reference,
          item_material: props.item_material,
          item_size: props.item_size,
          item_stone: props.item_stone,
          item_weight: props.item_weight,
          item_audience: props.item_audience,
        },
      ],
      user_logged: props.user_logged,
      user_id: props.user_id,
    })
  }, [])

  return { sendRemoveFromCartEvent }
}
