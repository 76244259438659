import { useMemo } from 'react'
import type { Session } from '@faststore/sdk'
import { createSessionStore } from '@faststore/sdk'
import { gql } from '@faststore/graphql-utils'
import { cartGenerate } from 'src/utils/generateCart'

import type {
  ValidateSessionMutation,
  ValidateSessionMutationVariables,
} from '../../../@generated/graphql/index'
import storeConfig from '../../../store.config'
import { cartStore } from '../cart'
import { request } from '../graphql/request'
import { createValidationStore, useStore } from '../useStore'

export const mutation = gql`
  mutation ValidateSession($session: IStoreSession!, $search: String!) {
    validateSession(session: $session, search: $search) {
      locale
      channel
      country
      postalCode
      currency {
        code
        symbol
      }
      person {
        id
        email
        givenName
        familyName
      }
    }
  }
`

export const validateSession = async (session: Session) => {
  const data = await request<
    ValidateSessionMutation,
    ValidateSessionMutationVariables
  >(mutation, { session, search: window?.location?.search })

  return data?.validateSession
}

const [validationStore, onValidate] = createValidationStore(validateSession)
const defaultStore = createSessionStore(storeConfig.session, onValidate)

export const sessionStore = {
  ...defaultStore,
  set: (val: Session) => {
    defaultStore.set(val)

    // Trigger cart revalidation when session changes
    cartStore.set(cartStore.read())
  },
}

export async function resetCart() {
  const isBrowser = typeof window !== 'undefined'

  if (isBrowser) {
    const urlParameters = window?.location?.search
      ?.split('&')
      ?.filter((parameter) => parameter?.indexOf('orderform=new') !== -1)

    if (urlParameters.length) {
      await cartGenerate({ reload: false, forceNewCart: true })

      window.location.assign(
        'https://secure.vivara.com.br/api/vtexid/pub/logout?scope=lojavivara&returnUrl=/'
      )
    }
  }

  return false
}

export const useSession = () => {
  const session = useStore(sessionStore)
  const isValidating = useStore(validationStore)

  return useMemo(
    () => ({
      ...session,
      isValidating,
    }),
    [isValidating, session]
  )
}
