import React, { memo, useEffect } from 'react'
import type { CartItem } from 'src/sdk/cart'
import type { AnalyticsItem } from 'src/sdk/analytics/types'
import type {
  AddToCartEvent,
  CurrencyCode,
  RemoveFromCartEvent,
} from '@faststore/sdk'
import { useSession } from 'src/sdk/session'
import { useAddToCart } from 'src/sdk/analytics/hooks/useAddToCartEvent'
import { useRemoveFromCart } from 'src/sdk/analytics/hooks/useRemoveFromCartEvent'
import { QuantitySelector as UIQuantitySelector } from '@faststore/ui'
import { sendAnalyticsEvent } from '@faststore/sdk'
import { Plus as PlusIcon, Minus as MinusIcon } from 'phosphor-react'
import './quantity-selector.scss'

interface QuantitySelectorProps {
  max?: number
  min?: number
  initial?: number
  disabled?: boolean
  item?: CartItem
  setQuantity?: React.Dispatch<React.SetStateAction<number>>
  quantity: number
  limitQuantity?: number | false
}

export type ProductPropertieType = {
  name: string
  propertyID: string
  value: string
  valueReference: string
}

export function QuantitySelector({
  max,
  min = 1,
  initial,
  disabled = false,
  item,
  setQuantity,
  quantity,
  limitQuantity,
}: QuantitySelectorProps) {
  const { sendAddToCartEvent } = useAddToCart()
  const { sendRemoveFromCartEvent } = useRemoveFromCart()
  const isLeftDisabled = quantity === min || limitQuantity === undefined
  const isRightDisabled =
    quantity === max ||
    (limitQuantity && quantity >= limitQuantity) ||
    limitQuantity === undefined

  const {
    currency: { code },
    person,
  } = useSession()

  useEffect(() => {
    if (limitQuantity && quantity > limitQuantity) {
      setQuantity?.(limitQuantity)
    }
  }, [quantity, limitQuantity])

  const changeQuantity = (increaseValue: number, typeOfEvent: string) => {
    const quantityValue = validateQuantityBounds(quantity + increaseValue)
    const verifyQuantityWithLimit = () =>
      limitQuantity === false ||
      (limitQuantity && quantityValue <= limitQuantity)

    if (verifyQuantityWithLimit()) {
      setQuantity?.(quantityValue)

      if (!item) {
        return false
      }

      const productID =
        item?.itemOffered?.isVariantOf?.additionalProperty?.filter(
          (propertie: ProductPropertieType) => propertie.name === 'Cód'
        )

      const valueProductID: string = productID?.[0]?.value
        ? productID?.[0]?.value
        : item?.itemOffered?.gtin

      if (typeOfEvent === 'remove_from_cart') {
        sendRemoveFromCartEvent({
          currency: code as CurrencyCode,
          value: Number((item.price * item.quantity).toFixed(2)),
          item_id: item.itemOffered?.sku,
          item_name: item.itemOffered.name,
          price: item.listPrice,
          quantity: item.quantity,
          discount: Number(
            ((item.listPrice - item.price) * item.quantity).toFixed(2)
          ),
          item_brand: item.itemOffered.brand.name ?? null,
          item_collection:
            item.itemOffered.isVariantOf.additionalProperty.find(
              (property) =>
                property.name.toLowerCase() === 'coleção'.toLowerCase()
            )?.value ?? null,
          item_type: item.itemOffered.name?.match(/^\p{L}+/u)?.[0] ?? null,
          item_reference: item.itemOffered.gtin,
          item_material:
            item.itemOffered.isVariantOf.additionalProperty.find(
              (property) =>
                property.name.toLowerCase() === 'material'.toLowerCase()
            )?.value ??
            item.itemOffered.isVariantOf.additionalProperty.find(
              (property) =>
                property.name.toLowerCase() === '1-material'.toLowerCase()
            )?.value ??
            item.itemOffered.isVariantOf.additionalProperty.find(
              (property) =>
                property.name.toLowerCase() ===
                'material da pulseira'.toLowerCase()
            )?.value ??
            null,
          item_size:
            item.itemOffered.isVariantOf.additionalProperty.find(
              (property) =>
                property.name.toLowerCase() === 'tamanho'.toLowerCase()
            )?.value ??
            item.itemOffered.additionalProperty.find(
              (property) => property.name.toLowerCase() === 'aro'.toLowerCase()
            )?.value ??
            item.itemOffered.isVariantOf.additionalProperty.find(
              (property) =>
                property.name.toLowerCase() ===
                'comprimento aprox'.toLowerCase()
            )?.value ??
            item.itemOffered.isVariantOf.additionalProperty.find(
              (property) =>
                property.name.toLowerCase() === 'altura aprox'.toLowerCase()
            )?.value ??
            item.itemOffered.isVariantOf.additionalProperty.find(
              (property) =>
                property.name.toLowerCase() === 'largura aprox'.toLowerCase()
            )?.value ??
            null,
          item_stone:
            item.itemOffered.isVariantOf.additionalProperty.find(
              (property) =>
                property.name.toLowerCase() === 'pedras'.toLowerCase()
            )?.value ??
            item.itemOffered.isVariantOf.additionalProperty.find(
              (property) =>
                property.name.toLowerCase() === 'pedra_valor'.toLowerCase()
            )?.value ??
            null,
          item_weight: item.itemOffered.name.match(/(\d+)\s*k/i)?.[0] ?? null,
          item_audience:
            item.itemOffered.isVariantOf.additionalProperty.find(
              (property) =>
                property.name.toLowerCase() === 'sugestão'.toLowerCase()
            )?.value ?? null,
          user_logged: !!person?.id,
          user_id: person?.id ?? null,
        })

        sendAnalyticsEvent<RemoveFromCartEvent<AnalyticsItem>>({
          name: 'remove_from_cart',
          params: {
            currency: code as CurrencyCode,
            value: item.price * item.quantity,
            items: [
              {
                item_id: item.itemOffered.sku,
                item_name: item.itemOffered.isVariantOf.name,
                item_brand: item.itemOffered.brand.name,
                item_variant: item.itemOffered.sku,
                quantity: item.quantity,
                price: item.price,
                discount: item.listPrice - item.price,
                currency: code as CurrencyCode,
                item_variant_name: item.itemOffered.name,
                product_reference_id: valueProductID,
                dimension1: item.itemOffered.gtin,
              },
            ],
          },
        })
      }

      if (typeOfEvent === 'add_to_cart') {
        sendAddToCartEvent({
          currency: code as CurrencyCode,
          value: Number((item.price * item.quantity).toFixed(2)),
          item_id: item.itemOffered?.sku,
          item_name: item.itemOffered.name,
          price: item.listPrice,
          quantity: item.quantity,
          discount: Number(
            ((item.listPrice - item.price) * item.quantity).toFixed(2)
          ),
          item_brand: item.itemOffered.brand.name ?? null,
          item_collection:
            item.itemOffered.isVariantOf.additionalProperty.find(
              (property) =>
                property.name.toLowerCase() === 'coleção'.toLowerCase()
            )?.value ?? null,
          item_type: item.itemOffered.name?.match(/^\p{L}+/u)?.[0] ?? null,
          item_reference: item.itemOffered.gtin,
          item_material:
            item.itemOffered.isVariantOf.additionalProperty.find(
              (property) =>
                property.name.toLowerCase() === 'material'.toLowerCase()
            )?.value ??
            item.itemOffered.isVariantOf.additionalProperty.find(
              (property) =>
                property.name.toLowerCase() === '1-material'.toLowerCase()
            )?.value ??
            item.itemOffered.isVariantOf.additionalProperty.find(
              (property) =>
                property.name.toLowerCase() ===
                'material da pulseira'.toLowerCase()
            )?.value ??
            null,
          item_size:
            item.itemOffered.isVariantOf.additionalProperty.find(
              (property) =>
                property.name.toLowerCase() === 'tamanho'.toLowerCase()
            )?.value ??
            item.itemOffered.additionalProperty.find(
              (property) => property.name.toLowerCase() === 'aro'.toLowerCase()
            )?.value ??
            item.itemOffered.isVariantOf.additionalProperty.find(
              (property) =>
                property.name.toLowerCase() ===
                'comprimento aprox'.toLowerCase()
            )?.value ??
            item.itemOffered.isVariantOf.additionalProperty.find(
              (property) =>
                property.name.toLowerCase() === 'altura aprox'.toLowerCase()
            )?.value ??
            item.itemOffered.isVariantOf.additionalProperty.find(
              (property) =>
                property.name.toLowerCase() === 'largura aprox'.toLowerCase()
            )?.value ??
            null,
          item_stone:
            item.itemOffered.isVariantOf.additionalProperty.find(
              (property) =>
                property.name.toLowerCase() === 'pedras'.toLowerCase()
            )?.value ??
            item.itemOffered.isVariantOf.additionalProperty.find(
              (property) =>
                property.name.toLowerCase() === 'pedra_valor'.toLowerCase()
            )?.value ??
            null,
          item_weight: item.itemOffered.name.match(/(\d+)\s*k/i)?.[0] ?? null,
          item_audience:
            item.itemOffered.isVariantOf.additionalProperty.find(
              (property) =>
                property.name.toLowerCase() === 'sugestão'.toLowerCase()
            )?.value ?? null,
          user_logged: !!person?.id,
          user_id: person?.id ?? null,
        })

        sendAnalyticsEvent<AddToCartEvent<AnalyticsItem>>({
          name: 'add_to_cart',
          params: {
            currency: code as CurrencyCode,
            value: item.price * item.quantity,
            items: [
              {
                item_id: item.itemOffered.sku,
                item_name: item.itemOffered.isVariantOf.name,
                item_brand: item.itemOffered.brand.name,
                item_variant: item.itemOffered.sku,
                quantity: item.quantity,
                price: item.price,
                discount: item.listPrice - item.price,
                currency: code as CurrencyCode,
                item_variant_name: item.itemOffered.name,
                product_reference_id: valueProductID,
                dimension1: item.itemOffered.gtin,
              },
            ],
          },
        })
      }
    }

    return true
  }

  const increase = () => changeQuantity(1, 'add_to_cart')

  const decrease = () => {
    changeQuantity(-1, 'remove_from_cart')
  }

  function validateQuantityBounds(n: number): number {
    const maxValue = min ? Math.max(n, min) : n

    return max ? Math.min(maxValue, max) : maxValue
  }

  function validateInput(e: React.FormEvent<HTMLInputElement>) {
    const val = e.currentTarget.value

    if (!Number.isNaN(Number(val))) {
      const quantityValue = validateQuantityBounds(Number(val))

      if (
        limitQuantity === false ||
        (limitQuantity && quantityValue <= limitQuantity)
      ) {
        setQuantity?.(quantityValue)
      } else if (max && limitQuantity && quantityValue > limitQuantity) {
        const valueMax = limitQuantity > max ? max : limitQuantity

        setQuantity?.(valueMax)
      }
    }
  }

  useEffect(() => {
    initial && setQuantity?.(initial)
  }, [initial])

  return (
    <UIQuantitySelector
      data-fs-quantity-selector={disabled ? 'disabled' : 'true'}
      quantity={quantity}
      leftButtonProps={{
        onClick: decrease,
        disabled: isLeftDisabled || disabled,
        icon: <MinusIcon size={16} weight="bold" />,
      }}
      rightButtonProps={{
        onClick: increase,
        disabled: isRightDisabled || disabled,
        icon: <PlusIcon size={16} weight="bold" />,
      }}
      inputProps={{
        onChange: validateInput,
        readOnly: false,
        disabled,
      }}
    />
  )
}

export default memo(QuantitySelector)
