import { useCallback } from 'react'

type Props = {
  pdp_version: 'versao_1' | 'versao_2'
  component:
    | 'botao'
    | 'imagem'
    | 'dropdown'
    | 'form'
    | 'produtos'
    | 'botao_sublinhado'
  action: 'click' | 'click_product' | 'form_submit' | 'click_dropdown'
  element_name?: string
  section:
    | 'info_gerais'
    | 'imagem_principal'
    | 'detalhamento_produto'
    | 'produtos_relacionados'
    | 'secao_inferior'
  position_interaction:
    | 'direita'
    | 'esquerda'
    | 'meio'
    | 'superior'
    | 'inferior'
  user_logged: boolean
  user_id: string | null
}

export const useProductAbTestEvent = () => {
  window.dataLayer = window.dataLayer || []
  const { dataLayer } = window

  const sendProductAbTestEvent = useCallback((props: Props) => {
    dataLayer.push({
      event: 'pdp_interaction',
      params: {
        ...props,
      },
    })
  }, [])

  return { sendProductAbTestEvent }
}
