import React from 'react'

import type { MaxPriceProps, MessageProps } from './PromotionBar'
import { setMessageInfo } from './PromotionBar'
import ModalPromotion from './ModalPromotions'

interface Props {
  pdpMessage: MessageProps[]
  hasMaxPrice: boolean
  maxPrice: MaxPriceProps
}

const PdpPromotionBar = ({ pdpMessage, hasMaxPrice, maxPrice }: Props) => {
  const hasPdpMessage = pdpMessage && pdpMessage.length > 0

  return hasPdpMessage ? (
    <div className="promotion__bar-wrapper promotion__bar-hidden">
      <div className="promotion__bar-container">
        <>
          <div className="promotion__bar-message">
            {setMessageInfo(pdpMessage)}
          </div>
          <div className="promotion__bar-base-container">
            <div className="iconBagPromotion" aria-label="Bag Icon" />
            <p className="promotion__bar-actual-price">
              R$
              {pdpMessage.filter((m) => m.visiblePromo)[0]?.cartItemPrice}
            </p>
            <div className="promotion__bar-base-bar">
              {hasMaxPrice && (
                <>
                  <div
                    className="promotion__bar-loading"
                    style={{
                      width: `${
                        (maxPrice.missingPrice * 100) / maxPrice.maxPromoPrice
                      }%`,
                    }}
                  />
                  <div
                    className="promotion__bar-loading-radio"
                    style={{
                      left: `calc(${
                        (maxPrice.missingPrice * 100) / maxPrice.maxPromoPrice
                      }% - 8px)`,
                    }}
                  />
                  {pdpMessage.map((message: MessageProps, idx: number) => (
                    <div
                      className={`promotion__bar-point ${message?.priceStatus}`}
                      key={idx}
                      style={{
                        left: `${
                          (message.floorPrice * 100) / maxPrice.maxPromoPrice
                        }%`,
                      }}
                    />
                  ))}
                </>
              )}
            </div>
            <p className="promotion__bar-full-price">
              R${hasMaxPrice && maxPrice.maxPromoPrice}
            </p>
          </div>
        </>
      </div>
      {<ModalPromotion message={pdpMessage} />}
    </div>
  ) : null
}

export default PdpPromotionBar
