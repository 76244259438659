import axios from 'axios'

export const getHasTransacted = async () => {
  try {
    const response = await axios.get('/api/getHasTransacted')

    return response?.data
  } catch (error) {
    console.error(error)

    return false
  }
}
