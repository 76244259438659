import React, { useState } from 'react'
import { useSession } from 'src/sdk/session'
import { useProductAbTestEvent } from 'src/sdk/analytics/hooks/useProductAbTestEvent'
import { isNewPDP } from 'src/utils/isNewPDP'

import type { MessageProps } from './PromotionBar'

interface Props {
  message: MessageProps[]
}

const ModalPromotion = ({ message }: Props) => {
  const [openModal, setOpenModal] = useState<boolean>(false)
  const { person } = useSession()
  const { sendProductAbTestEvent } = useProductAbTestEvent()

  const bodyElement = document.querySelector('body') as HTMLElement

  const fixedBuyWrapperOld = document.querySelector(
    '.header-bar-container'
  ) as HTMLElement

  const fixedBuyWrapperNew = document.querySelector(
    '.a-productDetailsFixedBar'
  ) as HTMLElement

  const handleModalOpen = () => {
    setOpenModal(true)
    bodyElement.style.overflow = 'hidden'

    if (fixedBuyWrapperOld) {
      fixedBuyWrapperOld.style.opacity = '0'
    }

    if (fixedBuyWrapperNew) {
      fixedBuyWrapperNew.style.opacity = '0'
    }
  }

  const handleModalClose = () => {
    setOpenModal(false)
    bodyElement.style.overflow = 'auto'

    if (fixedBuyWrapperOld) {
      fixedBuyWrapperOld.style.opacity = '1'
    }

    if (fixedBuyWrapperNew) {
      fixedBuyWrapperNew.style.opacity = '1'
    }
  }

  const sendSelectItemAbTestEventToAnalytics = () => {
    sendProductAbTestEvent({
      pdp_version: isNewPDP ? 'versao_2' : 'versao_1',
      action: 'click',
      component: 'botao_sublinhado',
      position_interaction: 'direita',
      element_name: 'regras_frete_gratis',
      section: 'info_gerais',
      user_id: person?.id ?? null,
      user_logged: !!person?.id,
    })
  }

  return (
    <>
      <button
        className="promotion__bar-more"
        onClick={() => {
          sendSelectItemAbTestEventToAnalytics()
          handleModalOpen()
        }}
      >
        SAIBA MAIS
      </button>
      {openModal && (
        <div className="promo__modal-wrapper">
          <div className="promo__modal-container">
            <div className="promo__modal-button">
              <button onClick={handleModalClose} />
            </div>
            <p>ENTENDA AS REGRAS</p>
            <div className="promo__modal-promos">
              {message.map((m) => (
                <a
                  href={m.promoPage}
                  className="promo__modal-name-promo"
                  key={m.key}
                  target="_blank"
                  rel="noreferrer"
                >
                  {m.promoName}
                </a>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ModalPromotion
