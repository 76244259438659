import React, { useContext, createContext } from 'react'
import type { FC } from 'react'
import { useSession } from 'src/sdk/session'
import { InsiderObjectUser } from 'src/components/InsiderObjectUser/InsiderObjectUser'

const UserContext = createContext<UserContextProps>({} as UserContextProps)

export const UserProvider: FC<React.PropsWithChildren> = ({ children }) => {
  const { person: user, isValidating } = useSession()

  InsiderObjectUser(user)

  return (
    <UserContext.Provider value={{ user, isValidating }}>
      {children}
    </UserContext.Provider>
  )
}

export const useUserContext = () => {
  return useContext(UserContext)
}
