import React, { createContext, useState, useContext } from 'react'

interface HeaderContextType {
  isMenuOpen: boolean
  departmentLevel1: string
  departmentLevel2: string
  setIsMenuOpen: (isMenuOpen: boolean) => void
  updateDepartmentLevel1: (department: string) => void
  updateDepartmentLevel2: (department: string) => void
}

const HeaderContext = createContext<HeaderContextType | undefined>(undefined)

export const HeaderProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
  const [departmentLevel1, setDepartmentLevel1] = useState<string>('')
  const [departmentLevel2, setDepartmentLevel2] = useState<string>('')

  const updateDepartmentLevel1 = (department: string) => {
    setDepartmentLevel1(department)
  }

  const updateDepartmentLevel2 = (department: string) => {
    setDepartmentLevel2(department)
  }

  return (
    <HeaderContext.Provider
      value={{
        isMenuOpen,
        departmentLevel1,
        departmentLevel2,
        setIsMenuOpen,
        updateDepartmentLevel1,
        updateDepartmentLevel2,
      }}
    >
      {children}
    </HeaderContext.Provider>
  )
}

export const useHeaderContext = (): HeaderContextType => {
  const context = useContext(HeaderContext)

  if (!context) {
    throw new Error('useHeaderContext must be used within a HeaderProvider')
  }

  return context
}
