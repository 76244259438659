import { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import { get } from 'idb-keyval'
import { getCookie, setCookie } from 'src/utils/cookie'
import { isStorageAvailable } from 'src/utils/isStorageAvailable'
import { formatCPF } from 'src/utils/formatCPF'
import { getHasTransacted } from 'src/utils/getHasTransacted'

interface User {
  id?: string
  givenName?: string
  familyName?: string
  email?: string
}

interface Data {
  gender?: string | null
  birthDate?: string
  isNewsletterOptIn?: boolean
  homePhone?: string
  isSMSOptIn?: boolean
  isWhatsappOptIn?: boolean
  document?: string
}

type ClientLifeLoverType = boolean | null | undefined

interface InsiderObject {
  data: Data
  user: User | null
  clientLifeLover: ClientLifeLoverType
  postalCode?: string
}

const getLifeLover = async (
  email: string | undefined,
  setClientLifeLover: React.Dispatch<
    React.SetStateAction<boolean | null | undefined>
  >
) => {
  try {
    const response = await axios.post('/api/getLifeLover', {
      email,
      headers: {
        'Content-Type': 'aplication/json',
        'REST-Range': 'resources=0-10',
      },
    })

    const isLifeLover = isStorageAvailable()
      ? sessionStorage.getItem('isLifeLover')
      : null

    if (isLifeLover === null) {
      if (!response.data[0]) {
        return null
      }

      const { data } = response

      setClientLifeLover(data[0]?.lifelover)

      if (isStorageAvailable()) {
        sessionStorage.setItem('isLifeLover', data[0]?.lifelover)
      }

      return response?.data?.[0]
    }

    setClientLifeLover(isLifeLover?.toLowerCase() === 'true')

    return response?.data?.[0]
  } catch (error) {
    console.error(error)

    return null
  }
}

const getCep = async () => {
  const session = navigator?.cookieEnabled && (await get('fs::session'))

  return session?.postalCode
}

const getRegionObject = (postalCodeProp?: string) => {
  if (!postalCodeProp) {
    return {
      cep_regiao: null,
      cep_subregiao: null,
      cep_setor: null,
      cep_subsetor: null,
      cep_divisorsub: null,
      cep_sufixo: null,
    }
  }

  return {
    cep_regiao: postalCodeProp.slice(0, 1),
    cep_subregiao: postalCodeProp.slice(1, 2),
    cep_setor: postalCodeProp.slice(2, 3),
    cep_subsetor: postalCodeProp.slice(3, 4),
    cep_divisorsub: postalCodeProp.slice(4, 5),
    cep_sufixo: postalCodeProp.slice(5, 9),
  }
}

const randomUUID = () =>
  typeof crypto.randomUUID === 'function'
    ? crypto.randomUUID().replace(/-/g, '')
    : (Math.random() * 1e6).toFixed(0)

async function getAddressData() {
  try {
    const { data: userAddressData } = await axios.post(
      '/api/getLoggedUserAddressInfo',
      {
        headers: {
          'Content-Type': 'application/json',
          'REST-Range': 'resources=0-10',
        },
      }
    )

    return {
      city: userAddressData?.[0]?.city || null,
      country: userAddressData?.[0]?.country || null,
      street: userAddressData?.[0]?.street || null,
      number: userAddressData?.[0]?.number || null,
      neighborhood: userAddressData?.[0]?.neighborhood || null,
      postalCode: userAddressData?.[0]?.postalCode || null,
      state: userAddressData?.[0]?.state || null,
      complement: userAddressData?.[0]?.complement || null,
    }
  } catch (error) {
    console.error('Error fetching user address data:', error)

    return null
  }
}

async function handleUserObjectInsider({
  data,
  user,
  clientLifeLover,
  postalCode,
}: InsiderObject) {
  window.insider_object = window.insider_object || {}

  let uuidCookie = getCookie('uuid')

  if (!uuidCookie) {
    uuidCookie = randomUUID()
    setCookie('uuid', uuidCookie, 7)
  }

  if (!user || !data) {
    window.insider_object.user = {
      uuid: uuidCookie,
      gdpr_optin: true,
      language: 'pt-BR',
    }

    return
  }

  let genderValue: string | false = ''

  if (data?.gender && data?.gender !== null) {
    genderValue = data?.gender?.toLowerCase() === 'male' ? 'M' : 'F'
  } else {
    genderValue = false
  }

  const postalCodeInformations = getRegionObject(postalCode)
  const userAddressData = await getAddressData()
  const hasTransacted = await getHasTransacted()

  window.insider_object.user = {
    uuid: user?.id,
    gender: genderValue,
    birthday: data.birthDate,
    gdpr_optin: true,
    name: user?.givenName,
    surname: user?.familyName,
    username: user?.givenName,
    email: user?.email,
    email_optin: data?.isNewsletterOptIn ?? false,
    phone_number: data?.homePhone,
    sms_optin: data?.isSMSOptIn ?? false,
    whatsapp_optin: data?.isWhatsappOptIn ?? false,
    language: 'pt-BR',
    country:
      userAddressData?.country === 'BRA' ? 'Brasil' : userAddressData?.country,
    city: userAddressData?.city,
    has_transacted: hasTransacted,
    custom: {
      is_lifelover: clientLifeLover ?? false,
      endereco: userAddressData ? userAddressData?.street : '',
      numero_end: userAddressData ? userAddressData?.number : '',
      complemento: userAddressData ? userAddressData.complement : '',
      bairro: userAddressData ? userAddressData?.neighborhood : '',
      cep_numerico: userAddressData
        ? userAddressData?.postalCode?.match(/^\d+(?=-)/)[0]
        : '',
      estado: userAddressData ? userAddressData?.state : '',
      ...postalCodeInformations,
    },
    custom_identifiers: {
      cpf: formatCPF(data?.document),
    },
  }
}

export const InsiderObjectUser = (user: User | null) => {
  const [clientLifeLover, setClientLifeLover] = useState<
    boolean | null | undefined
  >()

  const [postalCode, setPostalCode] = useState<string>('')
  const [lifeLoverData, setLifeLoverData] = useState<Data | null>(null)
  const [isDataReady, setIsDataReady] = useState<boolean>(false)
  const isDataReadyRef = useRef<boolean>(false)
  const userRef = useRef<User | null>(null)

  useEffect(() => {
    userRef.current = user
  }, [user])

  useEffect(() => {
    const fetchData = async () => {
      const cep = await getCep()

      setPostalCode(cep || '')

      if (userRef.current) {
        const data = await getLifeLover(
          userRef.current.email,
          setClientLifeLover
        )

        setLifeLoverData(data)
      } else if (isStorageAvailable()) {
        sessionStorage.removeItem('isLifeLover')
      }

      setIsDataReady(true)
    }

    fetchData()
  }, [user])

  useEffect(() => {
    isDataReadyRef.current = isDataReady
  }, [isDataReady])

  useEffect(() => {
    if (!isDataReady) {
      return
    }

    handleUserObjectInsider({
      data: lifeLoverData ?? {},
      user: userRef.current,
      clientLifeLover,
      postalCode,
    })

    setIsDataReady(false)
  }, [isDataReady])
}
